import axios from 'axios'

const state = {

};

const getters = {

};

const actions = {

    createLead: async ({ dispatch }, datas) => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_INTRANET_API_URL + "lead", datas,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                }
            )
            .then((response) => {
                dispatch('auth/updateToken', response.data.token, { root: true });             
                resolve(response.data);
            })
            .catch((error) => reject(error));
        });
    },

    updateLead: async ({ rootGetters }, datas) => {
        return new Promise((resolve, reject) => {
            const token = rootGetters['auth/getToken'];
            if (!token) {
                reject(new Error('No api token found'));
                return;
            }
    
            axios.put(process.env.VUE_APP_INTRANET_API_URL + "lead", datas,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => reject(error));
        });
    },

    submitLead: async ({ rootGetters }, datas) => {
        return new Promise((resolve, reject) => {
            const token = rootGetters['auth/getToken'];
            if (!token) {
                reject(new Error('No api token found'));
                return;
            }
    
            axios.post(process.env.VUE_APP_INTRANET_API_URL + "lead/submit", datas,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => reject(error));
        });
    },

}

const mutations = {

};

export default {
    namespaced: true,
	state,
	getters,
	actions,
	mutations
};