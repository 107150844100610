<template>
    <v-row class="px-3 mt-5 flex-column" style="width: 100%">
        <div class="d-flex justify-space-between align-start w-100" :class="{'flex-column': $vuetify.display.xs, 'gap-4': $vuetify.display.xs}">
            <a 
                href="https://app.hubspot.com/meetings/marc-serban?uuid=cb0b4d09-b738-4a9f-ad49-37419f3bf360" 
                target="_blank"
                class="text-primary mb-3"
            >
                <span>Vous hésitez ? Faites vous rappeler</span>
            </a>
            <router-link to="summary">
                <v-btn class="font-weight-normal" color="primary">
                    S<span class="text-lowercase">uivant</span>
                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                </v-btn>
            </router-link>
        </div>
    </v-row>
</template>