import axios from 'axios'

const state = {
    isAuth: JSON.parse(localStorage.getItem('is_auth')) || false,
    token: localStorage.getItem('api_token') || null
};

const getters = {
    isAuth: state => state.isAuth,
    getToken: state => state.token
};

const actions = {

    login: async ({ commit, dispatch }, params) => {
        return axios.post(process.env.VUE_APP_INTRANET_API_URL + "form/auth/login", params, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(async (response) => {
            const token = response.data.token;
            
            commit('setToken', token);
            commit('setAuth', true);

            await dispatch('payload/clearPayload', null, { root: true });
    
            if (response.data.lead.parent) {
                await dispatch('payload/updateParent', response.data.lead.parent, { root: true });
            }
    
            if (response.data.lead.students) {
                for (const student of response.data.lead.students) {
                    await dispatch('payload/addStudent', student, { root: true });
                }
            }
    
            return token;
        })
        .catch((error) => {
            throw error;
        });
    },

    logout({ commit }) {
        commit('logout');
    },

    updateToken({ commit }, token) {
        commit('setToken', token);
    },

    clearApiTokenAndAuth({ commit }) {
        commit('clearApiTokenAndAuth');
    }

}

const mutations = {

    setToken(state, token) {
        state.token = token;
        if (token) {
            localStorage.setItem('api_token', token);
        }
    },
    
    setAuth(state, status) {
        state.isAuth = status;
        localStorage.setItem('is_auth', JSON.stringify(status));
    },
    
    logout(state) {
        if (localStorage.getItem('api_token')) {
            localStorage.removeItem('api_token');
            localStorage.removeItem('appPayload');
            localStorage.removeItem('is_auth');
            state.selectedChild = null;
            state.user = null;
            state.isAuth = false;
        }
    },
    
    clearApiTokenAndAuth(state) {
        state.token = null;
        state.isAuth = null;
        localStorage.removeItem('api_token');
        localStorage.removeItem('is_auth');
    },
};

export default {
    namespaced: true,
	state,
	getters,
	actions,
	mutations
};