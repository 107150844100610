<template>
    <div>
        <v-card-title class="mb-2">
            <div class="d-flex align-center">
                <div class="font-weight-bold title">
                    {{ session.product_name }}
                </div>
                <v-icon @click="removePreparation()" icon="mdi-trash-can-outline" class="ml-3" size="x-small" color="primary"></v-icon>
            </div>
        </v-card-title>
        <v-card-text class="text-start">
            <v-row>
                <v-col v-if="getProductSubtitleDescription(session.descriptions)" class="pt-0">
                    <span class="font-weight-bold subtitle subtitle-description">{{ getProductSubtitleDescription(session.descriptions) }}</span>
                </v-col>
                <v-col cols=12 class="py-0">
                    <span class="font-weight-medium session-infos"><b>Élève</b> : {{ childName }}</span>
                </v-col>
                <v-col cols=12 class="py-0">
                    <span class="font-weight-medium session-infos"><b>Classe</b> : {{ session.class }}</span>
                </v-col>
                <v-col v-if="session.type_id == typeCourseFormule" cols=12 class="py-0">
                    <span class="font-weight-medium session-infos"><b>Date et centre de stage</b> : Le choix des dates et lieux de stages se fera après l’inscription</span>
                </v-col>
                <v-col v-if="session.type_id != typeCourseFormule" cols=12 class="py-0">
                    <span class="font-weight-medium session-infos"><b>Date</b> : 
                        {{ formatDate(session.start_date_displayed || session.start_date) }} au 
                        {{ formatDate(session.end_date_displayed || session.end_date) }}
                    </span>
                </v-col>
                <v-col cols=12 class="py-0">
                    <span v-if="session.location" class="font-weight-medium session-infos"><b>Centre de stage</b> : {{ session.location.description }} ({{ session.location.city }} {{ session.location.postcode }})</span>
                </v-col>
                <v-col cols=12 class="py-0">
                    <span class="font-weight-medium session-infos"><b>Prix</b> : {{ sessionPrice }} €</span>
                </v-col>
                <v-col v-if="session.type_id != typeCourseFormule" cols=12 class="py-0 mt-2">
                    <span class="font-weight-medium "><b>Info pratique :</b> L'emploi du temps sera accessible sur votre espace personnel.</span>
                </v-col>
            </v-row>
        </v-card-text>
    </div>
</template>

<script>

import moment from 'moment';

export default {
    
    props: {
        childName: String,
        session: Object,
        studentIndex: Number,
        sessionPrice: Number,
    },

     data() {
        return {
            descriptionProductSubtitleCode: 1800767,
            typeCourseFormule: 1900341
        }
    },

    methods: {

        getProductSubtitleDescription(descriptions) {
            const description = descriptions.find(description => description.category_id == this.descriptionProductSubtitleCode);
            return description ? description.short_description : '';
        },

        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },

        removePreparation() {
            this.$store.dispatch('payload/removeStudentPreparation', {
                studentIndex: this.studentIndex,
                preparationId: this.session.id_ammon
            });
        },
    },
}

</script>

<style scoped>

.subtitle-description{
    color: #10757a;
}

.title {
    font-size: 20px;
    white-space: normal;
    text-align: start;
}

</style>