<template>
    <v-container class="mt-8">
        <v-row justify="center">
            <v-col cols="12" md="8" class="text-center">
                <h1 class="text-h4 text-primary mb-6">
                    Votre pré-inscription a bien été enregistrée !
                </h1>
                
                <v-card class="pa-6 mb-6">
                    <div class="text-h6 mb-4">
                        Il ne vous reste plus qu'à effectuer le paiement pour définitivement réserver votre place :
                    </div>
                    
                    <v-card-text class="pa-0">
                        <div class="d-flex flex-column align-center mb-4">
                            <div class="text-body-1 mb-3">
                                Connectez-vous sur votre compte via "Mon Compte sur notre site web ou en cliquant ici :
                            </div>
                            <v-btn
                                color="primary"
                                href="https://intranet.cours-thales.fr/"
                                target="_blank"
                                variant="elevated"
                                class="px-6"
                            >
                                Mon compte
                            </v-btn>
                        </div>
                        
                        <div class="text-body-1 mb-6">
                            Un mail de création de compte vous a été envoyé si vous faites appel à nous pour la 1ère fois
                        </div>

                        <v-card
                            color="grey-lighten-4"
                            class="pa-4"
                        >
                            <div class="d-flex align-center">
                                <v-avatar
                                    color="primary"
                                    class="mr-4"
                                >
                                    <v-icon>mdi-phone</v-icon>
                                </v-avatar>
                                <div class="text-left">
                                    <div class="text-body-1 font-weight-medium mb-1">
                                        Pour un paiement en plusieurs fois :
                                    </div>
                                    <div class="text-body-1">
                                        Contacter notre équipe au 
                                        <span class="font-weight-bold">01 42 05 41 36</span>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>