<template>
    <v-row justify=center class="mt-4">
        <v-col cols=9>
            <user-creation-header step=1 title="Vos coordonnées" :alreadyClient="true"/>
            <div class="text-start text-primary font-weight-bold text-h6 mt-6">Déjà client ? : 
                <router-link to="/login" class="login-link font-weight-medium">connectez-vous</router-link>
            </div>
            <div class="text-start text-primary font-weight-bold text-h6 mt-3 mb-3">Créer un compte :</div>
            <div class="text-start text-primary font-weight-bold text-h6 mt-3 mb-6">Coordonnées du responsable</div>
            <user-creation-form class="mb-6"/>
        </v-col>
    </v-row>
</template>

<script>

import UserCreationHeader from '@/components/layout/HeaderBase.vue';
import UserCreationForm from '@/components/user-creation/UserCreationForm.vue';

export default {
    components: {
        UserCreationHeader,
        UserCreationForm
    },
};

</script>

<style scoped>

.login-link {
    font-size: 18px;
    color: #DC3545;
}

.subtitle-contact {
    font-weight: 400 !important;
}

</style>
