<template>
    <v-row justify=center class="mt-4">
        <v-col cols=9>
            <confirmation-inscription-header step=5 />
            <confirmation-inscription />
        </v-col>
    </v-row>
</template>

<script>

import ConfirmationInscriptionHeader from '@/components/layout/HeaderBase.vue';
import ConfirmationInscription from '@/components/confirmation-inscription/ConfirmationInscription.vue';

export default {

    components: {
        ConfirmationInscriptionHeader,
        ConfirmationInscription,
    },

};

</script>