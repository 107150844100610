<template>
    <v-form>
        <v-row align="start">
            <v-col cols="12" md="6">
                <select-field-with-title 
                    v-model="selectedChild" 
                    label="Pour quel élève ?" 
                    :items="childrens" 
                    :itemTitle="'name'"
                    :hide-details="true"
                    :returnObject="true"
                    variant="underlined"
                    :persistent-placeholder=false
                ></select-field-with-title>
            </v-col>
        </v-row>
        <v-row>
            <router-link to="child-creation" class="text-start text-primary pl-3 mb-3">
                Ajouter ou modifier un autre enfant
            </router-link> 
        </v-row>
        <v-row>
            <v-col cols=12 class="pb-0">
                <div class="text-start">Indiquez la classe de l’élève <b>en septembre 2024</b></div>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mb-1">
                <select-field-with-title 
                    v-model="selectedClass" 
                    label="Choisissez la classe" 
                    :items="classes"
                    :itemTitle="'label'"
                    :itemValue="'code'"
                    :hide-details="true"
                    variant="underlined"
                ></select-field-with-title>
            </v-col>
            <v-col v-if="selectedChild && selectedClass && filteredProductsByFormationWithoutStage.length > 0" cols="12" md="6" class="pt-0">
                <v-select
                    v-model="selectedFormation" 
                    label="Choisissez une préparation" 
                    :items="filteredProductsByFormation" 
                    :itemTitle="'title'"
                    :itemValue="'value'"
                    :hide-details="true"
                    variant="underlined"
                >
                </v-select>
            </v-col>
            <v-col v-if="selectedChild && filteredProductsByClass.length > 0 && selectedFormation == 'STAGE' || selectedClass && filteredProductsByFormationWithoutStage.length < 1" cols="12" md="6" class="pt-0">
                <select-field-with-title 
                    v-model="selectedDiscipline" 
                    label="Choisissez une matière" 
                    :items="filteredProductsByClass"
                    :itemTitle="'title'"
                    :itemValue="'value'"
                    :chips="true"
                    :closableChips="true"
                    :multiple="true"
                    :hide-details="true"
                    variant="underlined"
                ></select-field-with-title>
            </v-col>
        </v-row>
        <v-progress-circular
            v-if="loading"
            class="mt-5"
            indeterminate
            color="primary"
        ></v-progress-circular>
    </v-form>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';

import SelectFieldWithTitle from '@/components/form-base/SelectFieldWithTitleBase.vue';

export default {

    components: {
        SelectFieldWithTitle
    },

    props: {
        resetResults: Function
    },

    data() {
        return {
            loading: false,
            selectedChild: null,
            selectedClass: null,
            childClass: null,
            selectedDiscipline: null,
            selectedFormation: null,
            captionsFormations: [],
            captionsMatieres: [],
            childrens: [],
            classes: [],
            products: [],
        };
    },

    computed: {

        ...mapGetters('payload', ['getStudents']),

        filteredProductsByClass: function () {

            let productsAlreadyAdd = []
            let products = this.products
                .filter((item) => {
                    return item.class_code == this.childClass;
                })
                .map((item) => {
                    if (item.admission_modality && !productsAlreadyAdd.includes(item.admission_modality)) {
                        productsAlreadyAdd.push(item.admission_modality)
                        const caption = this.captionsMatieres.find(discipline => discipline.code === item.admission_modality)
                        return {
                            value: item.admission_modality,
                            title: caption.label,
                            order: caption.order
                        }
                    }
                })
                .filter((value, index, categoryArray) =>
                    categoryArray.indexOf(value) === index && value !== undefined
                )
                .sort((a, b) => {
                    if (a.order === null && b.order === null) {
                    return 0;
                    } else if (a.order === null) {
                    return 1;
                    } else if (b.order === null) {
                    return -1;
                    } else {
                    return a.order - b.order;
                    }
                })

            return products;
        },

        filteredProductsByFormation: function () {
            let productsAlreadyAdd = []
            let products = this.products
                .filter((item) => {
                    return item.class_code == this.childClass;
                })
                .map((item) => {
                    if (item.rhythm && !productsAlreadyAdd.includes(item.rhythm)) {
                        productsAlreadyAdd.push(item.rhythm)
                        const caption = this.captionsFormations.find(discipline => discipline.code === item.rhythm)
                        return {
                            value: item.rhythm,
                            title: caption.label,
                            order: caption.order
                        }
                    }
                })
                .sort((a, b) => {
                    if (a.order === null && b.order === null) {
                        return 0;
                    } else if (a.order === null) {
                        return 1;
                    } else if (b.order === null) {
                        return -1;
                    } else {
                        return a.order - b.order;
                    }
                });

            products = products.filter((value, index, categoryArray) =>
                categoryArray.indexOf(value) === index && value !== undefined
            );

            return products;
        },

        filteredProductsByFormationWithoutStage() {
            if (this.filteredProductsByFormation && this.filteredProductsByFormation.length > 0 && this.filteredProductsByFormation[0].value === 'STAGE') {
                return this.filteredProductsByFormation.slice(1);
            } else {
                return this.filteredProductsByFormation;
            }
        },

        hasStageItem() {
            return (
                this.filteredProductsByFormation && 
                this.filteredProductsByFormation.length > 0 &&
                this.filteredProductsByFormation[0].value === 'STAGE'
            );
        }
        
    },

    methods: {

        getClassesConstants() {
            this.$store.dispatch('getClassesConstants')
            .then(response => {
                this.classes = response
            })
        },

        getFormationsConstants() {
            this.$store.dispatch('getFormationsConstants')
            .then(response => {
                this.captionsFormations = response
            })
        },

        getProducts() {
            this.$store.dispatch('getProducts')
            .then(response => {
                this.products = response
            })
        },

        getChildrens() {
            this.childrens = this.getStudents.map((student, index) => ({
                name: `${student.firstname} ${student.lastname}`,
                index: index
            }));
            this.selectedChild = this.childrens[0];
            this.$emit('child-selected', this.selectedChild);
        },

        getSubjectsConstants() {
             this.$store.dispatch('getSubjectsConstants')
            .then(response => {
                this.captionsMatieres = response
            })
        },

        filterProducts() {
            const selectedDiscipline = this.selectedDiscipline;
            const selectedFormation = this.selectedFormation === 'STAGE' || this.filteredProductsByFormationWithoutStage.length < 1 ? "STAGE" : [this.selectedFormation];
            const childClass = this.childClass;
            const currentDate = moment();

            const filteredProducts = this.products.filter(product => {
                return (!selectedDiscipline || selectedDiscipline.includes(product.admission_modality)) 
                        && (!selectedFormation || product.rhythm == selectedFormation) 
                        && (!childClass || product.class_code == childClass)
                        && product.actions.some(action => 
                            (action.type_id == 1900036 && moment(action.start_date).isSameOrAfter(currentDate)) ||
                            (action.type_id == 1900341 && (
                                product.formule_type != 80001 || 
                                (product.formule_type == 80001 && moment(action.start_date).isSameOrAfter(currentDate))
                            ))
                        );
            });

            this.$emit('search-results', Object.values(filteredProducts));

        },
    },

    mounted() {
        this.getChildrens()
        this.getClassesConstants();
        this.getSubjectsConstants();
        this.getFormationsConstants();
        this.getProducts();
    },

    watch: {

        selectedChild(newValue) {
            this.$emit('child-selected', newValue);
        },

        selectedClass(newVal) {
            this.childClass = newVal
            this.selectedDiscipline = null
            this.selectedFormation = null
            this.$emit('clear-search-results')
        },

        childClass(newVal) {
            this.$store.dispatch('updateSelectedClass', newVal);
        },

        selectedDiscipline(value) {
            if(value) {
                this.filterProducts();
            }
        },

        selectedFormation(value) {
            this.$emit('clear-search-results')
            if(value && value != 'STAGE') {
                this.selectedDiscipline = null
                this.filterProducts();
            }
        },
        
    },
};

</script>