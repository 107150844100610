<template>
    <v-container class="mt-8">
        <v-row justify="center">
            <v-col cols="12" md="8" class="text-center">
                <h1 class="text-h4 text-primary mb-6">
                    Vous souhaitez plus d'éléments avant de vous décider ?
                </h1>

                <v-card class="pa-6">
                    <div class="text-h6 mb-4">
                        Notre conseiller pédagogique va vous renseigner :
                    </div>

                    <v-btn
                        color="primary"
                        variant="outlined"
                        prepend-icon="mdi-calendar"
                        class="mb-6 px-4"
                        href="https://app.hubspot.com/meetings/marc-serban?uuid=cb0b4d09-b738-4a9f-ad49-37419f3bf360"
                        target="_blank"
                    >
                        Choisir un créneau de rappel
                    </v-btn>

                    <v-divider class="mb-6"></v-divider>

                    <div class="text-subtitle-1 mb-4">
                        Vous pouvez également nous contacter :
                    </div>

                    <div class="d-flex flex-column align-center gap-3">
                        <div class="text-body-1">
                            <v-icon 
                                color="primary" 
                                class="mr-2"
                            >
                                mdi-phone
                            </v-icon>
                            Par téléphone : 
                            <span class="font-weight-bold">01 42 05 41 36</span>
                        </div>
                        
                        <div class="text-body-1 mt-2">
                            <v-icon 
                                color="primary" 
                                class="mr-2"
                            >
                                mdi-email
                            </v-icon>
                            Par email : 
                            <a 
                                href="mailto:contact@cours-thales.fr" 
                                class="text-primary text-decoration-none font-weight-medium"
                            >
                                contact@cours-thales.fr
                            </a>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>